<template>
  <v-dialog v-model="dialog" max-width="650" persistent transition="dialog-bottom-transition">
    <v-card tile>
      <v-toolbar flat dark color="primary">
        <v-btn icon dark @click="close()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Sessões do Usuário</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>
      <v-container fluid>
        <v-list subheader>
          <v-card-title>Sessões Ativas: {{ activeSessions ? activeSessions : '-' }}</v-card-title>
          <v-row>
            <v-col cols="6">
              <v-subheader>Plataforma:</v-subheader>
              <v-row v-if="activePlatformSession && activePlatformSession.length">
                <v-col cols="12">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>ID_ACCOUNT</v-list-item-title>
                      <v-list-item-subtitle>{{ activePlatformSession[0].data.id_account || '-' }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>DB_NAME</v-list-item-title>
                      <v-list-item-subtitle>{{ activePlatformSession[0].data.db_name || '-' }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>CREATED_AT</v-list-item-title>
                      <v-list-item-subtitle>{{ $moment(activePlatformSession[0].created_at).format('DD/MM/YYYY, HH:mm:ss') }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>UPDATED_AT</v-list-item-title>
                      <v-list-item-subtitle>{{ $moment(activePlatformSession[0].updatedAt).format('DD/MM/YYYY, HH:mm:ss') }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>EXPIRES_AT</v-list-item-title>
                      <v-list-item-subtitle>{{ $moment(activePlatformSession[0].expires_at).format('DD/MM/YYYY, HH:mm:ss') }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <v-card-title v-else>Não há sessão ativa.</v-card-title>
            </v-col>
            <v-col cols="6">
              <v-subheader>Starlord:</v-subheader>
              <v-row v-if="activeAdminSession && activeAdminSession.length">
                <v-col cols="12">
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>ID_ACCOUNT</v-list-item-title>
                      <v-list-item-subtitle>{{ activeAdminSession[0].data.id_account || '-' }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>

                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>DB_NAME</v-list-item-title>
                      <v-list-item-subtitle>{{ activeAdminSession[0].data.db_name || '-' }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>CREATED_AT</v-list-item-title>
                      <v-list-item-subtitle>{{ $moment(activeAdminSession[0].created_at).format('DD/MM/YYYY, HH:mm:ss') }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>UPDATED_AT</v-list-item-title>
                      <v-list-item-subtitle>{{ $moment(activeAdminSession[0].updatedAt).format('DD/MM/YYYY, HH:mm:ss') }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title>EXPIRES_AT</v-list-item-title>
                      <v-list-item-subtitle>{{ $moment(activeAdminSession[0].expires_at).format('DD/MM/YYYY, HH:mm:ss') }}</v-list-item-subtitle>
                    </v-list-item-content>
                  </v-list-item>
                </v-col>
              </v-row>
              <v-card-title v-else>Não há sessão ativa.</v-card-title>
            </v-col>
          </v-row>
        </v-list>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  props: {
    dialog: Boolean,
    session: Array
  },
  data: () => ({
    warning: false
  }),
  computed: {
    activeSessions() {
      let active
      if (this.session) {
        active = this.session?.filter(v => v.enabled)
      }
      return active ? active.length : null
    },
    activePlatformSession() {
      let active
      if (this.session) {
        active = this.session?.filter(v => v.enabled && v.session === 'platform')
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      if (active && active.length > 1) this.warning = true
      return active || null
    },
    activeAdminSession() {
      let active
      if (this.session) {
        active = this.session?.filter(v => v.enabled && v.session === 'admin')
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      if (active && active.length > 1) this.warning = true
      return active || null
    }
  },
  methods: {
    close() {
      this.$emit('close')
    }
  }
}
</script>
